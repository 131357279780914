// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("trix")
require("@rails/actiontext")
require("punycode")
import "cocoon"


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

window.addEventListener('load', (event) => {
  // ハンバーガーメニュー
  $(document).on('click', function (e) {
    // クリックされた場所の判定
    if (!$(e.target).closest('#js-sidemenu').length && !$(e.target).closest('#js-sidemenu-open').length) {
      $('#js-sidemenu').removeClass("active");
    } else if ($(e.target).closest('#js-sidemenu-open').length) {
      $('#js-sidemenu').toggleClass("active");
    }
  });

  // お知らせモーダル
  $(document).on('click', function (e) {
    // クリックされた場所の判定
    if (!$(e.target).closest('#notificationModal').length && !$(e.target).closest('#notificationModalOpener').length) {
      $('#notificationModal').hide();
    } else if ($(e.target).closest('#notificationModalOpener').length) {
      // モーダルの表示状態の判定
      if ($('#notificationModal').is(':hidden')) {
        $('#notificationModal').show();
      } else {
        $('#notificationModal').hide();
      }
    }
  });

  $(".header-select").change(function () {
    let selectValue = $(this).val();
    if (selectValue !== "") {
      $(this).css("color", "black");
    } else {
      $(this).css("color", "#C8C8C8");
    };
  });

  // TODO: 市区町村の選択肢を変動させる場面がいくつかあるためapplication.jsにて定義しているが、別の場所にまとめたほうがわかりやすい
  $('.select-prefecture').change(function () {
    let prefectureId = $(this).find('option:selected').val();
    const selectCity = $(".select-city");
    if (prefectureId) {
      $.ajax({
        url: "/get_city_list?prefecture_id=" + prefectureId,
        type: "GET",
        dataType: "json"
      }).done(function(results) {
        selectCity.html("<option value>未選択</option>");
        selectCity.prop("disabled", false);
        $.each(results, function() {
          let city_data = this.attributes;
          let option = $("<option>").val(city_data.id).text(city_data.name);
          selectCity.append(option);
        });
      }).fail(function () {
        console.log('市区町村が読み込まれませんでした');
      });
    } else {
      // 再度都道府県の選択を「未選択」にすると市区町村の選択を解除およびdisabledになる
      selectCity.html("<option value>未選択</option>");
      selectCity.prop("disabled", true);
    }
  });
});
